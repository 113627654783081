body {
  font-family: 'Roboto', sans-serif;
}

.no-scroll {
  overflow: hidden;
}

.whiteboard-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.canvas {
  background: url(../public/grid-bg.svg);
}

.toolbar-toggle {
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 2em;
}

.toolbar-container {
  position: absolute;
  left: 24px;
  top: 50%;
  transition: all .1s ease-out;
  transform: translate(0, -50%);
}

.toolbar {
  background-color: white;
  border: 1px solid;
  border-radius: 32px;
  padding: 4px;
  box-shadow: 4px 4px rgb(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.toolbar-item {
  padding: 8px;
  font-size: 1.5em;
  transition: all .5s ease-in-out;
}

.toolbar-item :hover, .toolbar-toggle :hover {
  transform: scale(1.2);
}

.toolbar-menu {
  border: 1px solid;
  border-radius: 16px;
  box-shadow: 4px 4px rgb(0, 0, 0, 0.5);
  position: absolute;
  margin: 8px;
  padding: 16px;
  top: 0;
  left: 64px;
  background-color: white;
}

.toolbar-menu-pen {
  min-width: 192px;
}

.toolbar-menu-color {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 192px;
}

.toolbar-menu-color button {
  border: 1px solid black;
  margin: 8px;
  height: 32px;
  width: 32px;
}

.toolbar-menu-color button:hover {
  transform: scale(1.2);
}

.chatbox {
  background-color: white;
  position: fixed;
  bottom: 75px;
  right: 15px;
  width: 350px;
  height: calc(85% - 75px);
}

.chatbox-messages {
  height: 85%;
  overflow-y: auto;
}

/* .chatbox-msgbox {
  position: relative;
  height: 10%;
  margin: 8px;
} */

.chat-button {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.chat-msg .name {
  color: #666;
  font-size: 13px;
  position: relative;
  margin: 12px 0 4px 8px;
}

.chat-msg .message {
  background-color: #f8f8f8;
  font-size: 15px;
  max-width: 90%;
  width: auto;
  padding: 10px 14px;
}

a.router-link:visited {
  color: white;
}